@import '../../assests/mixins.scss';

.menu {

    :global(.ant-divider) {
        height: 58px !important;
        margin-top: 0 !important;
        width: 1px !important;
        min-width: auto;

        background-color: $white  !important;
        margin: 0
    }



    :where(.css-dev-only-do-not-override-14wwjjs).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after {
        border-bottom: none;
    }

    .ant-dropdown-trigger:hover {
        color: white !important
    }



    :global(.ant-dropdown-menu-item) {
        padding: 0.8rem 0.8rem 0;
    }

    :global(.ant-menu-dark) {
        :global(.ant-menu-item-divider) {
            background-color: $text-darken-more  !important;
        }

        .icon {
            color: $gray-darken;
        }

        .title {
            color: $gray-darken;
        }
    }

    .itemText {
        font-size: 10px;
        line-height: 13px;
        margin-top: -14px;
        margin-bottom: 3px;
    }

    :global(.ant-menu-light) {
        .icon {
            color: $text;
        }

        .title {
            color: $text;
        }
    }
}

.logo {
    height: 40px;
    width: 100px;
    padding: 5px;
    // background: white;
    float: left;
}

:global(.ant-menu-item) {
    display: flex !important;
    // justify-content: center !important;
    align-items: center !important;

}

:global(.ant-menu-item-selected) {
    background-color: transparent !important;
    // border-bottom: 2px white solid !important;
    border-bottom: none !important;
    border-radius: 0;
}

:global(.ant-menu-item-selected)::after {
    background-color: transparent !important;
    border-bottom: 0 white solid !important;
    // border-bottom: none !important;
    border-radius: 0;
}

.logoContainer {
    width: 80px;
    height: 45px;
    padding: 10px;

    img {
        width: 100px;

    }
}

.icon {
    margin-top: '8px  rem(7)';
    margin-right: rem(7);

    @media (max-width: $md-max-width) {
        display: none !important;
    }
}

:global(.settings__menuTop) .menu {
    display: inline-flex;
    flex-direction: row;

    .icon {
        order: -1;
    }
}

:global(.settings__menuTop .ant-menu-item) {
    display: inline-flex;
    flex-direction: row;
    border-left: 1px solid #e8e8e8;
    border-right: 1px solid #e8e8e8;

    .icon {
        order: -1;
    }
}

:global(.settings__menuTop .ant-menu-light) {
    border-bottom: none;
    box-shadow: inset 0 -1px 0 #e8e8e8;
}

.temp {
    :global(.ant-comment-inner) {
        padding: 0 !important;
    }

    .ant-dropdown-menu {
        -webkit-box-shadow: none;
        box-shadow: none !important;
        border: 1px solid black;
    }
}

.drawerDark {
    background-color: transparent !important;

    :global(.rc-drawer-content-wrapper) {
        width: 100px !important
    }
}

.scroll {
    outline: none;
    width: 470px;
    max-height: 500px;
    padding-top: 12px;
    padding-bottom: 12px;
    overflow-y: scroll;
}

/* width */
.scroll::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

/* Track */
.scroll::-webkit-scrollbar-track {
    background: #fff;
}

/* Handle */
.scroll::-webkit-scrollbar-thumb {
    background: #ccc;
}

/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}