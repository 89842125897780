.notification {
    .ant-popover-content {
        margin-inline-end: 10px !important;
    }
}

.callContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.call-animation {
    background: green;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: solid 5px rgb(171, 213, 154);
    animation: call 1.5s ease infinite;
    color: aliceblue;
    font-size: 35px;
    font-weight: bold;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

// .caller-img {
//     position: absolute;
//     height: 50px;
//     width: 50px;
//     top: 35px;
//     left: 35px;
// }

@keyframes call {
    15% {
        box-shadow: 0 0 0 4px rgba(76, 112, 3, 0.5);
    }

    25% {
        box-shadow: 0 0 0 8px rgba(77, 187, 22, 0.425),
            0 0 0 16px rgba(5, 138, 54, 0.3);
    }

    30% {
        box-shadow: 0 0 0 12px rgba(255, 255, 255, 0.5),
            0 0 0 24px rgba(255, 255, 255, 0.3);
    }
}